import ProjectComponent from '../../Project-Component';
import rilogo from '../assets/RI-logo.jpeg';

function SocialAssistence() {

    return (
        <main>
            <ProjectComponent projectName={"Residência Inclusiva"} city={"Casa Branca - SP"} logo={rilogo}></ProjectComponent>
        </main>
    )
}

export default SocialAssistence;